<template>
    <div>
        <span>
            <span v-for="level_id in level_ids" :key="level_id" @mouseleave="hidePopup()" @mouseover="showHierarchy(level_id)" class="cursor-pointer me-1">
                {{ getLevelNameForID(level_id) }}
            </span>
        </span>
        <!-- Bootstrap Vue Tooltip -->
        <b-tooltip v-if="isTooltipVisible" :show="isTooltipVisible" :target="tooltipTarget" triggers="hover" placement="bottom">
        {{ hierarchy }}
        </b-tooltip>
    </div>
</template>

<script>
import { levelComputed } from '@/state/helpers'
export default {
    props: {
        level_ids:{
            default: []
        }
    },
    data:() => ({
        isTooltipVisible: false,
        tooltipTarget: null,
        hierarchy: "",
    }),
    computed:{
        ...levelComputed,
    },
    methods:{
        getLevelNameForID(id){
            let lvl = this.levels.find( level => { return id == level.id });
            return lvl.name;
        },
        getLevelFromID(id){
            let lvl = this.levels.find( level => { return id == level.id });
            return lvl;
        },
        showHierarchy(item) {
            // Build the hierarchy string
            this.hierarchy = this.buildHierarchy(item);
            this.tooltipTarget = event.target;
            this.$nextTick(()=>{
                this.isTooltipVisible = true;
            })
        },
        hidePopup(){
            this.isTooltipVisible = false;
            this.hierarchy = '';
            this.tooltipTarget = null;
        },
        buildHierarchy(id) {
            let main_lvl = this.getLevelFromID(id);
            let hierarchy = main_lvl.name
            let parentId = main_lvl.parent_id;
            while (parentId !== null) {
                const parentItem = this.levels.find((item) => item.id === parentId);
                if (parentItem) {
                hierarchy = parentItem.name + " >> " + hierarchy;
                parentId = parentItem.parent_id;
                } else {
                parentId = null;
                }
            }
            return hierarchy;
        },
    },
    mounted(){},
}
</script>

<style>

</style>