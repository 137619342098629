<!--
@Author: Calvin Green <calvin>
@Date:   2021-12-14T11:24:47+02:00
@Email:  calvin@diversitude.com
@Last modified by:   calvin
@Last modified time: 2021-12-16T08:05:23+02:00
@Copyright: Diversitude 2021
-->

<template>
    <Layout>
        <b-button @click="notif">Get</b-button>
        <div>
            <pre>{{ notifications }}</pre>
        </div>
        <TableComponent
            v-if="processed_array"
            :data="processed_array"
            :columns="columns"
            treetable
        />
        <hr>
        <span class="card-header-text">
            Card header text
        </span>
        <hr>
        <span class="normal-text">Normal text</span>
        <hr>
        <div class="animation-parent-container" style="border: 1px solid red;">
        <svg class="rocket"  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 382.667 382.667"><path d="M375.878.101c-.804-.123-1.621-.123-2.425 0-74.253 14.777-142.571 50.924-196.56 104l-70.56 3.6c-1.987.09-3.87.917-5.28 2.32l-72.48 72.48c-3.137 3.111-3.158 8.177-.047 11.314 1.514 1.526 3.577 2.379 5.727 2.366h.56l81.68-5.6c-3.2 6.72-6.24 13.6-9.04 20.8-1.161 2.95-.471 6.307 1.76 8.56l53.76 53.76c2.253 2.231 5.61 2.921 8.56 1.76 7.44-2.88 14.48-6.08 21.44-9.36l-5.6 82.56c-.254 4.411 3.116 8.193 7.527 8.447 2.256.13 4.463-.701 6.073-2.287l72.48-72.48c1.403-1.41 2.23-3.293 2.32-5.28l3.68-72c52.628-53.906 88.455-121.945 103.12-195.84.67-4.367-2.328-8.45-6.695-9.12zM55.053 178.421l55.2-55.2 51.28-2.64c-13.976 16.377-26.228 34.151-36.56 53.04l-69.92 4.8zm204.96 94.56l-55.2 55.2 4.8-70.88c18.896-10.382 36.671-22.687 53.04-36.72l-2.64 52.4zm-89.44-14.56l-46.32-46.32c58.32-142.48 199.12-184.48 240-193.84-9.2 41.04-50.96 181.76-193.68 240.16z"/><path d="M88.413 223.221c-3.115-3.159-8.201-3.195-11.36-.08l-74.24 73.92c-3.364 2.865-3.768 7.914-.904 11.278 1.614 1.895 4.018 2.931 6.504 2.802 2.126.012 4.17-.823 5.68-2.32l74.24-74.24c3.159-3.114 3.195-8.201.08-11.36zM90.225 292.557c-.017-.018-.034-.037-.052-.055-3.12-3.102-8.16-3.102-11.28 0l-74.32 74.64c-3.802 2.251-5.058 7.158-2.807 10.96 2.251 3.802 7.158 5.058 10.96 2.807 1.157-.685 2.122-1.65 2.807-2.807l74.24-74.24c3.247-2.998 3.449-8.059.452-11.305zM158.661 294.341c-2.995-2.565-7.412-2.565-10.407 0h.24l-74.24 74.24c-3.356 2.874-3.747 7.924-.873 11.28 2.874 3.356 7.924 3.747 11.28.873.313-.268.605-.56.873-.873l74-74.24c2.873-3.355 2.482-8.406-.873-11.28zM277.599 69.461c-19.617.004-35.517 15.91-35.513 35.527.002 9.42 3.745 18.453 10.407 25.113 6.648 6.683 15.693 10.428 25.12 10.4 19.617-.004 35.517-15.91 35.513-35.527-.004-19.617-15.91-35.517-35.527-35.513zm13.854 49.68c-7.767 7.571-20.153 7.571-27.92 0-7.619-7.622-7.619-19.978 0-27.6v-.32c7.71-7.71 20.21-7.71 27.92 0 7.71 7.71 7.71 20.21 0 27.92z"/></svg>
        </div>
        <hr>

        <div class="badge-item badge-item-milestone">Milestone</div>
        <div class="badge-item badge-item-objective">Objective</div>
        <div class="badge-item badge-item-key-result">Key Result</div>
        <div class="badge-item badge-item-key-performance-indicator">Key Performance Indicator</div>
        <div class="badge-item badge-item-operational-metric">Metric</div>
        <div class="badge-item badge-item-key-action">Key Action</div>
        <hr>
        <Bread :level_ids="[118]"/>
        <hr>
        <Bread :level_ids="[72]"/>
        <hr>
        <Bread :level_ids="[72, 139]"/>
    </Layout>
</template>

<script>
import TableComponent from '@/components/widgets/table/table copy.vue';
import Layout from "../layouts/main";
//import Table from '@/components/widgets/table/table.vue'
import { levelComputed, menuMethods, adminComputed, adminMethods } from '@/state/helpers'
//eslint-disable-next-line
import _ from 'lodash'
import Bread from './breadcrumb.vue'
export default {
    name: "TestZone",
    components:{
    TableComponent,
    Bread,
    //Table,
    Layout,
},
    mounted(){
        this.setLoading(false);
        this.origional_array = this.generateItems();
        this.buildObjectivesTree(this.origional_array);
    },
    //watch:{
    //    origional_array(){
    //        this.buildObjectivesTree(this.origional_array)
    //    }
    //},
    data: () => ({
        processed_array : [],
    }),
    methods:{
        ...menuMethods,
        ...adminMethods,
        notif(){
            this.loadNotifications(0);
        },
        async buildObjectivesTree(flat_objectives){
            const map = {};
            const nestedArray = [];
            let pre_processed_array = [];
            return new Promise( (resolve) => {
                if(flat_objectives.length == 0){
                    resolve();
                }       
                //* get unique by id
                pre_processed_array = _.uniqBy(flat_objectives, 'id');
                //* set to correct order
                //pre_processed_array = _.orderBy(pre_processed_array, ['item_order']);
                pre_processed_array = pre_processed_array.map((item) => {
                    let new_item = {
                        ...item,
                        "data": [],
                        "parent" : item.parent_id,
                        "text" : item.name,
                    }
                    map[item.id] = { ...new_item, };
                    return new_item;
                });
                pre_processed_array.forEach((item) => {
                    if (item.parent_id !== null) {
                        if (map[item.parent_id]) {
                            map[item.parent_id].data.push(map[item.id]);
                        }
                        else {
                            nestedArray.push(map[item.id]);
                        }
                    }
                    else {
                        nestedArray.push(map[item.id]);
                    }
                });
                //commit('setObjectivesTree', nestedArray);
                console.log(nestedArray)
                this.processed_array = nestedArray
                resolve();
            })
        },
        generateItems() {
            const items = [];
            const rootItems = [];

            // Helper function to generate a random date string
            function generateRandomDate() {
                const start = new Date(2022, 0, 1); // Start date (January 1, 2022)
                const end = new Date(); // Current date and time
                const randomDate = new Date(
                start.getTime() + Math.random() * (end.getTime() - start.getTime())
                );
                return randomDate.toISOString();
            }

            // Generate root items
            for (let i = 1; i <= 5; i++) {
                const item = {
                id: i,
                parent_id: null,
                name: `Root Item ${i}`,
                created_at: generateRandomDate(),
                };
                items.push(item);
                rootItems.push(i);
            }

            // Generate child items
            for (let i = 6; i <= 150; i++) {
                const parentIndex = Math.floor(Math.random() * rootItems.length);
                const parentID = rootItems[parentIndex];
                const item = {
                id: i,
                parent_id: parentID,
                name: `Child Item ${parentID}.${i - 75}`,
                created_at: generateRandomDate(),
                };
                items.push(item);
            }
            for (let i = 151; i <= 850; i++) {
                const parentIndex = Math.floor(Math.random() * items.length);
                const parentID = items[parentIndex].id;
                const item = {
                id: i,
                parent_id: parentID,
                name: `Child Item ${parentID}.${i - 75}`,
                created_at: generateRandomDate(),
                };
                items.push(item);
            }

            return items;
            }

    },


    computed:{
        ...levelComputed,
        ...adminComputed,
        table_data(){
            return _.cloneDeep(this.processed_array);
        },
        columns(){
            
            let el = this;
            let cols = [
                {
                    id: 'id',
                },
                {
                    id: 'name',
                    template: (obj, common, value) => {
                        if(value != ''){
                            return common.treetable(obj, common) + "<span>"+value+"</span>";
                        }
                        else{
                            return '';
                        }
                    },
                },
                {
                    id: 'parent_id', template(obj){
                        if(obj.parent_id == null){
                            return 'N/a'
                        }
                        else{
                            let idx = _.findIndex(el.origional_array, { id: obj.parent_id});
                            if(idx != -1){
                                return el.origional_array[idx].name;
                            }
                            else{
                                return -1;
                            }
                        }
                    }
                },
                {
                    id: 'created_at'
                }
            ];
            return cols;
        }
    },
}

</script>

<style>
.animation-parent-container {
    position: relative;
    width: 100%;
    height: 100px;
    width: 100px; /* Adjust the height as needed */
    overflow: hidden; /* Ensure the rocket does not go beyond the parent's boundaries */
}
.rocket {
    position: absolute;
    bottom: 0;
    left: 0; /* Start at the bottom left corner */
    animation: flyDiagonally 5s linear infinite; /* Customize the animation duration as needed */
}

@keyframes flyDiagonally {
    0% {
        transform: translate(0, -50%); /* Start position at the bottom left */
    }
    100% {
        transform: translate(50%, -50%); /* End position at the top right */
    }
}

</style>
